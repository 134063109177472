<template>
  <div class="w-full">
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false"/>
    </transition>
    <div class="w-full h-full flex flex-col items-center" :class="modal ? 'blur' : ''">
      <div class="wrapper">
        <div class="content container">
          <nav-bar @login="modal = true"/>
          <img alt="Pessoa" src="../../../assets/images/landing/investor_people.webp" class="wrapper_people"/>
          <div class="article">
            <div class="article_main">
              <p class="article_title">Seu investimento, com retorno justo e impacto social</p>
              <button class="btn_mobile" @click="$router.push('/investimento/solicitar')">Quero investir</button>
              <div class="article_cards">
                <div class="article_card">
                  <p class="article_card_title">Investimentos</p>
                  <p class="article_card_text">A partir de R$ 500,00 e possibilidades de alocar em diferentes empresas</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">Mitigação de Riscos</p>
                  <p class="article_card_text">Modelo proprietário de crédito sofisticado</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">Acesso facilitado</p>
                  <p class="article_card_text">Sem burocracia e toda contratação online</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">Recebimento mensal</p>
                  <p class="article_card_text">Com amortização e juros</p>
                </div>
              </div>
            </div>
            <img alt="Pessoa" class="w-full h-88 mt-10 md:hidden object-contain" src="../../../assets/images/landing/investor_people.webp"/>
            <button class="btn" @click="$router.push('/investimento/solicitar')">Quero investir</button>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col items-center">
        <div class="article_cards_mobile container">
          <div class="article_card">
            <p class="article_card_title">Investimentos</p>
            <p class="article_card_text">A partir de R$ 500,00 e possibilidades de alocar em diferentes empresas</p>
          </div>
          <div class="article_card">
            <p class="article_card_title">Mitigação de Riscos</p>
            <p class="article_card_text">Modelo proprietário de crédito sofisticado</p>
          </div>
          <div class="article_card">
            <p class="article_card_title">Acesso facilitado</p>
            <p class="article_card_text">Sem burocracia e toda contratação online</p>
          </div>
          <div class="article_card">
            <p class="article_card_title">Recebimento mensal</p>
            <p class="article_card_text">Com amortização e juros</p>
          </div>
        </div>
      </div>

      <div class="compare_main">
        <div class="compare container">
          <div class="compare_left">
            <p class="compare_left_title">Compare o investimento</p>
            <p class="compare_left_text">(1) Todas as Taxas são Brutas (antes do desconto do Imposto de Renda). Apenas a poupança é isenta de IR. </p>
            <p class="compare_left_text">(2) TuTu: Considerando uma taxa média de retorno de 25% ao ano e o reinvestimento das parcelas recebidas. </p>
            <p class="compare_left_text">(3) Tesouro Pre-fixado 2020 LTN - Fonte: tesouro.fazenda.gov.br/tesouro-direto-precos-e-taxas-dos-titulos. Consulta em 22/12/2017. </p>
            <p class="compare_left_text">(4) CDB de 110% do CDI - Consultado em 22/12/2017. </p>
            <p class="compare_left_text">(5) Poupança - Fonte: Banco Central do Brasil - Consulta em 22/12/2017.</p>
          </div>
          <div class="compare_right">
            <img alt="Gráfico de comparação" class="w-120 h-56" src="../../../assets/images/investor/investor_graphic.png">
          </div>
        </div>
      </div>

      <div class="container my-4 lg:my-12">
        <div class="how_working">
          <p class="how_working_title">É bem simples!</p>
          <p class="how_working_subtitle">Veja como funciona</p>
          <div class="block md:hidden lg:hidden">
            <carousel-items :items="[
            { src: require('@/assets/images/investor/investor_1.svg'), text: 'Você se cadastra' },
            { src: require('@/assets/images/investor/investor_2.svg'), text: 'Avalia e seleciona a empresa que quer investir' },
            { src: require('@/assets/images/investor/investor_3.svg'), text: 'Registre seu interesse' },
            { src: require('@/assets/images/investor/investor_4.svg'), text: 'Tenha retorno do seu investimento e acompanhe o impacto de milhões de empreendedores' }
          ]"/>
          </div>
          <div class="how_working_cards">
            <div class="how_working_card">
              <div class="how_working_icon_1 lg:self-start"></div>
              <p class="how_working_card_text lg:self-start">Você se cadastra</p>
              <i class="hidden absolute inset-y-0 right-0 mt-8 mr-4 fas fa-arrow-right text-secondary text-lg lg:block"></i>
            </div>
            <div class="how_working_card">
              <div class="how_working_icon_2"></div>
              <p class="how_working_card_text">Avalia e seleciona a empresa que quer investir</p>
              <i class="hidden absolute inset-y-0 right-0 mt-8 ml-4 fas fa-arrow-right text-secondary text-lg lg:block"></i>            </div>
            <div class="how_working_card">
              <div class="how_working_icon_3"></div>
              <p class="how_working_card_text">Registre seu interesse</p>
            </div>
            <div class="how_working_card">
              <div class="how_working_icon_4 lg:self-end"></div>
              <p class="how_working_card_text lg:self-end">Tenha retorno do seu investimento e acompanhe o impacto de milhões de empreendedores</p>
              <i class="hidden absolute inset-y-0 left-0 mt-8 ml-2 fas fa-arrow-right text-secondary text-lg lg:block"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import Footer from '@/components/Footer/Footer.vue'
import CarouselItems from '@/components/Carousel/Carousel.vue'

export default {
  name: 'investor',
  components: { NavBar, ChooseLoginModal, Footer, CarouselItems },
  data () {
    return {
      modal: false
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>

.wrapper
  @apply relative flex flex-col items-end w-full h-full bg-cover bg-center bg-no-repeat
  background-image: url("../../../assets/images/landing/wrapper.webp")
  @screen lg
    height: 768px

.content
  @apply relative h-full flex flex-col items-center

.wrapper_people
  @apply hidden
  @screen md
    @apply block absolute bottom-0 left-0 ml-0 w-88 h-88
  @screen lg
    @apply ml-16 w-136 h-136
  @screen xl
    @apply w-144 h-144

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

.article
  @apply flex flex-col items-end my-auto

.article_main
  @apply w-full mt-12 flex flex-col items-center
  @screen md
    @apply w-3/5 mb-4
  @screen lg
    @apply w-2/5 mb-10
  @screen xl
    @apply w-2/5 mr-20

.article_title
  @apply text-2xl text-primary font-bold leading-normal
  @screen md
    @apply w-10/12 text-2xl text-primary font-bold leading-normal
  @screen lg
    @apply w-full text-4xl text-primary font-bold leading-normal

.article_cards
  @apply hidden
  @screen md
    @apply block w-10/12 flex flex-wrap
  @screen lg
    @apply block w-full flex flex-wrap

.article_cards_mobile
  @apply block flex flex-wrap mb-6
  @screen md
    @apply hidden
  @screen lg
    @apply hidden

.article_card
  @apply relative w-1/2 mt-6

.article_card_title
  @apply text-secondary text-lg

.article_card_text
  @apply w-11/12 text-gray-700 text-sm mt-2
  @screen lg
    @apply w-11/12 text-gray-700 text-sm mt-2

.btn
  @apply hidden text-sm
  @screen md
    @apply block w-40 h-10 bg-secondary text-white rounded-full mt-4 mb-10 float-right
    &:hover, &:focus
      @apply outline-none
  @screen lg
    @apply block w-40 h-10 bg-secondary text-white rounded-full mt-8 mb-0 float-right
    &:hover, &:focus
      @apply outline-none

.btn_mobile
  @apply w-40 h-10 bg-secondary text-white rounded-full mt-8
  &:hover, &:focus
    @apply outline-none
  @screen md
    @apply hidden
  @screen lg
    @apply hidden

.compare_main
  @apply flex flex-col items-center w-full bg-secondary
  background: linear-gradient(to right, #5316CD, #625FB0)

.compare
  @apply flex flex-col items-center py-12
  @screen md
    @apply flex flex-row items-center py-12
  @screen lg
    @apply flex flex-row items-center py-12

.compare_left
  @apply w-full
  @screen lg
    @apply w-1/2

.compare_left_title
  @apply text-white text-2xl mb-6
  @screen lg
    @apply text-white text-4xl mb-8

.compare_left_text
  @apply text-white text-xss text-justify mt-2
  @screen lg
    @apply text-white text-sm text-justify mt-2

.compare_right
  @apply w-full flex justify-center items-center mt-6
  @screen lg
    @apply w-1/2 flex justify-center items-center

.how_working_title
  @apply text-2xl text-secondary font-bold leading-loose mt-6
  @screen lg
    @apply text-4xl text-secondary font-bold leading-loose mt-6

.how_working_subtitle
  @apply text-primary text-base
  @screen lg
    @apply text-primary text-2xl leading-loose

.how_working_cards
  @apply hidden
  @screen md
    @apply block w-full flex flex-wrap
  @screen lg
    @apply block w-full flex flex-wrap

.how_working_card
  @apply mt-8
  @screen md
    @apply relative w-1/4 mt-10 flex flex-col items-center
  @screen lg
    @apply relative w-1/4 mt-10 flex flex-col items-center

.how_working_icon_1
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../../assets/images/investor/investor_1.svg')
  @screen lg
    @apply w-40 ml-4 h-20 bg-contain bg-center bg-no-repeat mb-10
    background-image: url('../../../assets/images/investor/investor_1.svg')

.how_working_icon_2
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../../assets/images/investor/investor_2.svg')
  @screen lg
    @apply w-40 mr-2 h-20 bg-contain bg-center bg-no-repeat mb-10
    background-image: url('../../../assets/images/investor/investor_2.svg')

.how_working_icon_3
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../../assets/images/investor/investor_3.svg')
  @screen lg
    @apply w-40 ml-2 h-20 bg-contain bg-center bg-no-repeat mb-10
    background-image: url('../../../assets/images/investor/investor_3.svg')

.how_working_icon_4
  @apply w-32 h-16 bg-contain bg-center bg-no-repeat mb-10
  background-image: url('../../../assets/images/investor/investor_4.svg')
  @screen lg
    @apply w-40 mr-4 h-20 bg-contain bg-center bg-no-repeat mb-10
    background-image: url('../../../assets/images/investor/investor_4.svg')

.how_working_card_text
  @apply w-full text-center text-gray-700 text-sm
  @screen lg
    @apply w-48 text-center text-gray-700 text-sm
</style>
